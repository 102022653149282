
body {
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
}

.portrait {
  height:110%;
  width:auto;
}

.landscape {
  height:auto;
  width:110%;
}

#controls {
  position: absolute;
  bottom: 0;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  z-index:300;
}

#controls .circle {
  width: 40px;
  height: 40px;
  border-radius:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  border:1px solid white;
}

@media (min-width: 1024px) {
  #controls .circle {
    width: 80px;
    height: 80px;
  }
}

#controls .desc {
  color:white;
  text-align: center;
  width:1px;
  margin: 0 auto;
  margin-top:0.5em;
  text-align:center;
  white-space: nowrap;
  display:flex;
  justify-content:center;
  align-items:center;
}

#controls .btn {
  margin:60px 40px;
  cursor: pointer;
}

#controls .btn:active {
  opacity: 0.6;
}

#controls-audio img{
  width: 70%;
  height: 70%;
}

#controls-video img{
  width: 60%;
  height: 60%;
}

#controls-fullscreen img{
  width: 50%;
  height: 50%;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}



.userName {
  font-family: Monospace;
  position:absolute;
  left:0;
  bottom:0;
  background-color:rgba(0,0,0,0.5);
  color:white;
  display:inline-block;
  padding:0.3em 0.8em;
  box-sizing:border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:12px;
  max-width:100%;
}


.videoList {
  position:absolute;
  top:0;
  right:0;
  height:100vh;
  width:100vw;
  box-sizing: border-box;
  overflow:scroll;
  text-align:right;
}

.video-wrapper {
  background-color:black;
  display:flex;
  overflow: hidden;
  justify-content:center;
  align-items:center;
}

.speakerView .video-wrapper {
  display:inline-block;
  position:relative;
  width:200px;
  height:120px;
  max-width:20vw;
  max-height:15vw;
  z-index: 200;
  margin-top:20px;
  margin-right:20px;
  cursor: pointer;
  display:inline-flex;
  justify-content:center;
  align-items:center;
}

.speakerView .video-wrapper::after {content: '\A'; white-space: pre;}


.speakerView .video-wrapper.focused{
  background-color:rgba(0,0,0,0.7);
  width:100vw;
  height:100vh;
  max-width:100vw;
  max-height:100vh;
  position:fixed;
  left:0;
  top:0;
  z-index:100;
  margin:0;
  cursor:auto;
}


.center-view {
  width: 100vw;
  height: 100vh;
  display:flex;
  justify-content:center;
  align-items:center;
}



.dotted {
  padding: 2.25em 1.6875em;
  background-image: repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
  background-size: 3px 3px;
  }



